<template>
  <div class="intervene">
    <div class="info-title">
      <div class="info-box">
        <div class="left">
          <span class="span">日期</span>
          <el-date-picker type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="info-title">
      <div class="info-box">
        <div class="week">
          <div class="upper">
            <span class="el-icon-arrow-left"></span>
          </div>
          <div class="text">
            <span>本周</span>
          </div>
          <div class="text">
            <span>下周</span>
          </div>
          <div class="text">
            <span>大下周</span>
          </div>
          <div class="below">
            <span class="el-icon-arrow-right"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" height="340" :header-cell-style="{ background: '#024276' }">
        <el-table-column prop="name" label="疫苗类目"></el-table-column>
        <el-table-column label="2022/2/1周二"></el-table-column>
        <el-table-column label="2022/2/1周二"></el-table-column>
        <el-table-column label="2022/2/1周二"></el-table-column>
        <el-table-column label="2022/2/1周二"></el-table-column>
        <el-table-column prop="date" label="2022/2/1周二">
          <template slot-scope="scope">
            <div @click="openSee" class="click">
              <span>{{ scope.row.date }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="2022/2/1周二"></el-table-column>
        <el-table-column label="2022/2/1周二"></el-table-column>
      </el-table>
    </div>
    <See v-if="showSee" @close="closeSee"></See>
  </div>
</template>

<script>
import See from './see.vue'
export default {
  components: {
    See
  },
  data () {
    return {
      tableData: [{
        date: '40/40已排期',
        name: '2020031400',
        type: '1',
        value: false
      }, {
        date: '40/40已排期',
        name: '2020031400',
        type: '1',
        value: true
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      tabIndex: 0,
      showSee: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    }
  }
}
</script>

<style lang="less" scoped>
.intervene {
  position: relative;
  width: 100%;
  height: 100%;

  .info-title {
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 20px;
    font-weight: bold;
  }

  .info-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
    padding-bottom: 0;
    box-sizing: border-box;

    .left {
      display: flex;
      align-items: center;

      .span {
        margin-right: 8px;
        font-weight: 400;
      }

      .search-input {
        width: 260px;
      }

      .el-select,
      .el-input {
        width: 200px;
        margin-right: 10px;
      }

      .date {
        margin-right: 10px;
      }

      .blue,
      .gray {
        margin-left: 40px;
        background-color: #04B4D0;
        color: #fff;
        border: none;
        border-radius: 0;
      }

      .gray {
        margin-right: 10px;
        background-color: #284867;
      }
    }

    .right {
      width: .375rem;
      height: .375rem;
      line-height: .375rem;
      text-align: center;
      border-radius: 50%;
      background-color: #04B4D0;
      color: #fff;
      font-size: .225rem;
      font-weight: 600;
      cursor: pointer;
    }

    .week {
      display: flex;
      align-items: center;
      color: #FFFFFF;
      font-size: 16px;

      .upper {
        color: #01EEFD;
        font-size: 16px;
      }

      .below {
        color: #FFFFFF;
        font-size: 16px;
      }

      .text {
        padding: 0 30px;
        color: #FFFFFF;
        font-size: 16px;
      }
    }
  }

  .table {
    width: 99%;
    margin-top: 10px;
    border: 4px #073F73 solid;

    .click {
      cursor: pointer;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  /deep/.el-table__body tbody tr {
    color: #fff;
    font-size: 16px;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  ::v-deep .el-table__cell {
    border-right: .0125rem solid #01EEFD;
  }

  /deep/.el-table th.gutter {
    display: none;
    width: 0px;
  }

  /deep/.el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  /deep/ .el-checkbox__inner {
    background-color: transparent;
  }

  /deep/ .el-table__body-wrapper {
    background-color: #092B50;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: .2rem;

    span {
      color: #0077F9;
      cursor: pointer;
      letter-spacing: 0;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
