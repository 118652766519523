<template>
  <div class="knowledgeBase">
    <div class="tab">
      <span :class="{ title: tabIndex === 0 }" @click="tabClick(0)">疫苗预约排期</span>
      <span :class="{ title: tabIndex === 1 }" @click="tabClick(1)">疫苗种类管理</span>
    </div>
    <div class="content">
      <Tab1 v-if="tabIndex === 0"></Tab1>
      <Tab2 v-else-if="tabIndex === 1" @openAdd="openAdd"></Tab2>
    </div>
    <Add v-if="showAdd" @close="closeAdd"></Add>
  </div>
</template>

<script>
import Tab1 from './tab1.vue'
import Tab2 from './tab2.vue'
import Add from './add.vue'
export default {
  components: {
    Tab1,
    Tab2,
    Add
  },
  data () {
    return {
      tabIndex: 0,
      showAdd: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    }
  }
}
</script>

<style lang="less" scoped>
.knowledgeBase {
  position: relative;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  padding: 20px;
  border: 4px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;
  background-color: #08223D;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    span {
      height: 38px;
      line-height: 38px;
      margin-right: 16px;
      padding: 0 12px;
      text-align: center;
      color: #fff;
      font-size: 22px;
      background-color: #284867;
      cursor: pointer;
    }

    .title {
      background-color: #01EEFD;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 80px);
    margin-top: 20px;

    .intervene {
      position: relative;
      width: 100%;
      height: 100%;

      .info-title {
        width: 100%;
        height: 50px;
        margin-bottom: 20px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 20px;
        font-weight: bold;
      }

      .info-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 10px;
        padding-bottom: 0;
        box-sizing: border-box;

        .left {
          display: flex;
          align-items: center;

          .span {
            margin-right: 8px;
            font-weight: 400;
          }

          .search-input {
            width: 260px;
          }

          .el-select,
          .el-input {
            width: 200px;
            margin-right: 10px;
          }

          .date {
            margin-right: 10px;
          }

          .blue,
          .gray {
            margin-left: 40px;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            margin-right: 10px;
            background-color: #284867;
          }
        }

        .right {
          width: .375rem;
          height: .375rem;
          line-height: .375rem;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: .225rem;
          font-weight: 600;
          cursor: pointer;
        }

        .week {
          display: flex;
          align-items: center;
          color: #FFFFFF;
          font-size: 16px;

          .upper {
            color: #01EEFD;
            font-size: 16px;
          }

          .below {
            color: #FFFFFF;
            font-size: 16px;
          }

          .text {
            padding: 0 30px;
            color: #FFFFFF;
            font-size: 16px;
          }
        }
      }

      .table {
        width: 99%;
        margin-top: 10px;
        border: 4px #073F73 solid;
      }

      ::v-deep .el-table__body-wrapper {
        // &::-webkit-scrollbar {
        //   // 整个滚动条
        //   width: 0px; // 纵向滚动条的宽度
        // }
      }

      ::v-deep .el-table th.gutter {
        display: none;
        width: 0;
      }

      ::v-deep .el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      ::v-deep .el-table__body {
        width: 100% !important;
      }

      /deep/.el-table__header-wrapper {
        thead {
          th {
            div {
              color: #01EEFD;
              font-size: 17px;
              font-weight: 400;
            }
          }
        }
      }

      /deep/.el-table__body tbody tr {
        color: #fff;
        font-size: 16px;
        background-color: #092B50;
      }

      /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background-color: #092B50;
      }

      /deep/.el-table tbody tr:hover>td {
        background-color: transparent !important
      }

      .el-table--border,
      .el-table--group {
        border: 2px solid #ddd !important;
      }

      .el-table--border td,
      .el-table--border th,
      .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
        border-right: 1px solid #ddd !important;
      }

      /deep/.el-table td {
        border-bottom: 1px solid #01EEFD;
      }

      /deep/.el-table thead tr th {
        border-color: transparent;
      }

      /deep/ .el-table--border::after,
      /deep/ .el-table--group::after,
      /deep/ .el-table::before {
        background-color: #073F73;
      }

      /deep/.el-table--border {
        border: 1px solid #073F73 !important;
      }

      /deep/.el-table .cell {
        text-align: center;
        color: #01EEFD;
      }

      ::v-deep .el-table__cell {
        border-right: .0125rem solid #01EEFD;
      }

      /deep/.el-table th.gutter {
        display: none;
        width: 0px;
      }

      /deep/.el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      /deep/ .el-checkbox__inner {
        background-color: transparent;
      }

      /deep/ .el-table__body-wrapper {
        background-color: #092B50;
      }

      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: .2rem;

        span {
          color: #0077F9;
          cursor: pointer;
          letter-spacing: 0;
        }
      }

      .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 10px;

        ::v-deep.el-pagination__total,
        ::v-deep.el-pagination__jump {
          color: #01EEFD;

          .el-input__inner {
            color: #fff;
            background-color: transparent;
          }
        }

        ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #01EEFD;
        }
      }
    }
  }
}
</style>
