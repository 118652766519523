<template>
  <div class="see">
    <div class="info-title">
      <div class="info-box">
        <div class="left">
          <span class="span">疫苗名称：新冠疫苗</span>
          <span class="span">排期时间：2022/3/6 周二</span>
          <span class="span">选择时间</span>
          <el-date-picker type="date" placeholder="选择日期">
          </el-date-picker>
          <span class="span">数量</span>
          <el-input placeholder="请输入"></el-input>
          <el-button size="small" class="blue">添加</el-button>
        </div>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" height="340" :header-cell-style="{ background: '#024276' }">
        <el-table-column prop="name" label="时间段"></el-table-column>
        <el-table-column label="时间"></el-table-column>
        <el-table-column label="数量"></el-table-column>
        <el-table-column label="操作" width="120">
          <template>
            <!-- slot-scope="scope" -->
            <div class="flex">
              <span>编辑</span>
              <span>删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="btns">
      <el-button size="small" @click="close">返回</el-button>
      <el-button size="small" class="button">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '40/40已排期',
        name: '2020031400',
        type: '1',
        value: false
      }, {
        date: '40/40已排期',
        name: '2020031400',
        type: '1',
        value: true
      }]
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.see {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: #081C38;

  .info-title {
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 20px;
    font-weight: bold;
  }

  .info-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
    padding-bottom: 0;
    box-sizing: border-box;

    .left {
      display: flex;
      align-items: center;

      .span {
        margin-right: 20px;
        font-weight: 400;
      }

      .search-input {
        width: 260px;
      }

      .el-select,
      .el-input {
        width: 200px;
        margin-right: 10px;
      }

      .date {
        margin-right: 10px;
      }

      .blue,
      .gray {
        margin-left: 40px;
        background-color: #04B4D0;
        color: #fff;
        border: none;
        border-radius: 0;
      }

      .gray {
        margin-right: 10px;
        background-color: #284867;
      }
    }

    .right {
      width: .375rem;
      height: .375rem;
      line-height: .375rem;
      text-align: center;
      border-radius: 50%;
      background-color: #04B4D0;
      color: #fff;
      font-size: .225rem;
      font-weight: 600;
      cursor: pointer;
    }

    .week {
      display: flex;
      align-items: center;
      color: #FFFFFF;
      font-size: 16px;

      .upper {
        color: #01EEFD;
        font-size: 16px;
      }

      .below {
        color: #FFFFFF;
        font-size: 16px;
      }

      .text {
        padding: 0 30px;
        color: #FFFFFF;
        font-size: 16px;
      }
    }
  }

  .table {
    width: 99%;
    margin-top: 10px;
    border: 4px #073F73 solid;
  }

  ::v-deep .el-table__body-wrapper {
    // &::-webkit-scrollbar {
    //   // 整个滚动条
    //   width: 0px; // 纵向滚动条的宽度
    // }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  /deep/.el-table__body tbody tr {
    color: #fff;
    font-size: 16px;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  ::v-deep .el-table__cell {
    border-right: .0125rem solid #01EEFD;
  }

  /deep/.el-table th.gutter {
    display: none;
    width: 0px;
  }

  /deep/.el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  /deep/ .el-checkbox__inner {
    background-color: transparent;
  }

  /deep/ .el-table__body-wrapper {
    background-color: #092B50;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: .2rem;

    span {
      color: #0077F9;
      cursor: pointer;
      letter-spacing: 0;
    }
  }

  .btns {
    display: flex;
    align-items: center;
    height: 46px;
    margin: 10px auto 0;

    .el-button {
      margin-right: 50px;
      color: #fff;
      font-size: 14px;
      background-color: #33465C;
      border-radius: 0;
      border: none;
    }

    .button {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
