<template>
  <div class="knowledgeBase">
    <div class="tab">
      <span class="title">其他设置</span>
    </div>
    <div class="content">
      <div class="item">
        <span>修改密码</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="item">
        <span>重新输入</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="item">
        <span>封面</span>
        <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
          :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <div class="text" v-else>
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <span>点击上传</span>
          </div>
        </el-upload>
      </div>
      <div class="btns">
        <el-button size="small">清空</el-button>
        <el-button size="small" class="submit">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      imageUrl: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    }
  }
}
</script>

<style lang="less" scoped>
.knowledgeBase {
  position: relative;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  padding: 20px;
  border: 4px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;
  background-color: #08223D;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    span {
      height: 38px;
      line-height: 38px;
      margin-right: 16px;
      padding: 0 12px;
      text-align: center;
      color: #fff;
      font-size: 22px;
      background-color: #284867;
      cursor: pointer;
    }

    .title {
      background-color: #01EEFD;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 80px);
    margin-top: 20px;
    padding: 0 20px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      color: #04B4D0;
      font-size: 16px;

      span {
        width: 80px;
      }

      .el-input {
        width: 300px;
      }

      ::v-deep.avatar-uploader .el-upload {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 166px;
        height: 73px;
        border: 1px solid #FFFFFF;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        color: #fff;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        margin-right: 4px;
        font-size: 20px;
        color: #fff;
        text-align: center;
      }

      .avatar {
        width: 166px;
        height: 73px;
        display: block;
      }
    }

    .btns {
      display: flex;
      align-items: center;
      margin-top: 60px;

      .el-button {
        margin-right: 50px;
        border: none;
        border-radius: 0;
        background-color: #33465C;
        color: #fff;
      }

      .submit {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
